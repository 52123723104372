import * as React from 'react'
import type { PropsWithRequiredChildren } from '../../../types/helpers'
import { TabbedContentContext } from './TabContext'

export type ContentProps = {
  /**
   * Tab id used to display content when the relevant tab is active. This should match the `Tab`'s `id` attribute.
   */
  tabId: string
} & PropsWithRequiredChildren<React.ComponentPropsWithoutRef<'div'>>

export const Content: React.VFC<ContentProps> = (props) => {
  const { children, className, tabId, ...restProps } = props

  const { activeTab } = React.useContext(TabbedContentContext)

  if (activeTab !== tabId) return null
  return (
    <div className={className} {...restProps}>
      {children}
    </div>
  )
}
